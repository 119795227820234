<script lang="ts">
	import Paperplane from '$lib/images/svg/paperplane.svelte';
	import { twMerge } from 'tailwind-merge';
	let commonCls =
		'text-white font-semibold uppercase whitespace-nowrap text-center justify-center flex gap-x-4 items-center';
	export let isLarge: boolean = false;
	export let isSmall: boolean = false;
	export let onClick: (() => void) | undefined = undefined;
	export let isSend: boolean | undefined = undefined;
	export let isDark: boolean = false;
	export let isSubmit: boolean | undefined = undefined;
	export let isDisabled: boolean = false;
</script>

{#if $$props.href}
	<a
		href={$$props.href}
		class={twMerge(
			commonCls,
			$$props.class,
			'inline-flex',
			isDark ? 'bg-gray-shark hover:bg-gray-light' : 'hover:bg-redHover bg-red',
			isLarge
				? 'px-7 py-4 text-lg leading-none'
				: isSmall
					? 'flex p-0 text-sm leading-none'
					: 'px-9 py-2'
		)}
	>
		{#if isSend}<Paperplane class="w-4" />{/if}
		<slot />
	</a>
{:else if onClick}
	<button
		disabled={isDisabled}
		type={isSubmit ? 'submit' : 'button'}
		on:click={onClick}
		class={twMerge(
			commonCls,
			$$props.class,
			isDark ? 'bg-gray-shark hover:bg-gray-light' : 'hover:bg-redHover bg-red',
			isLarge
				? 'px-7 py-4 text-lg leading-none'
				: isSmall
					? 'flex px-4 py-2 text-sm font-normal leading-none'
					: 'px-9 py-2'
		)}
	>
		{#if isSend}<Paperplane class="w-4" />{/if}
		<slot />
	</button>
{:else}
	<button
		
		type="submit"
		class={twMerge(
			commonCls,
			$$props.class,
			isDark ? 'bg-gray-shark hover:bg-gray-light' : 'hover:bg-redHover bg-red',
			isLarge
				? 'px-7 py-4 text-lg leading-none'
				: isSmall
					? 'flex p-0 text-sm leading-none'
					: 'px-9 py-2'
		)}
	>
		{#if isSend}<Paperplane class="w-4" />{/if}
		<slot />
	</button>
{/if}
